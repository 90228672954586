@use './styles/material-components.scss';
@use './styles/material-theme.scss';
@use './styles/text.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Lato, sans-serif;
}

// GENERAL STYLING
:root {
  --swiper-pagination-color: #4b5654;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bottom: 0;
}

// ISSUES OF -> MATERIAL - TAILWIND
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}
.mat-mdc-input-element {
  box-shadow: none !important;
}
.mat-accordion {
  ::before,
  ::after {
    border-color: unset;
  }
}
