@use '@angular/material' as mat;
@use './material-theme-palette.scss' as palette;

@include mat.elevation-classes();
@include mat.app-background();

// DEFINE THEME
$sharelock-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: palette.$primary-palette,
      tertiary: palette.$tertiary-palette,
    ),
    typography: (
      plain-family: Lato,
      brand-family: Open Sans,
      bold-weight: 900,
      medium-weight: 600,
      regular-weight: 400,
    ),
  )
);

// SET GENERAL THEMES TO MATERIAL COMPONENTS
:root {
  --secondary: #38bce0; // should be replaced with --mat-sys-tertiary
  @include mat.all-component-themes($sharelock-theme);

  // PROGRESS BAR
  @include mat.progress-bar-color($sharelock-theme, $color-variant: secondary);

  // STEPPER
  @include mat.stepper-color($sharelock-theme, $color-variant: secondary);
  @include mat.stepper-overrides(
    (
      header-done-state-icon-background-color: var(--secondary),
      header-done-state-icon-foreground-color: white,
    )
  );

  // FORM-FIELD
  @include mat.form-field-color($sharelock-theme, $color-variant: secondary);

  // CARD
  @include mat.card-overrides(
    (
      elevated-container-color: white,
    )
  );

  // BUTTON
  @include mat.button-overrides(
    (
      filled-label-text-weight: 400,
      outlined-label-text-weight: 400,
      protected-label-text-weight: 400,
      text-label-text-weight: 400,
      text-label-text-color: black,
      outlined-label-text-color: black,
      filled-container-shape: 4px,
      outlined-container-shape: 4px,
      protected-container-shape: 4px,
      text-container-shape: 4px,
    )
  );

  // TABLE
  @include mat.table-overrides(
    (
      background-color: white,
    )
  );

  // PAGINATOR
  @include mat.paginator-overrides(
    (
      container-background-color: white,
    )
  );

  // SELECT
  @include mat.select-overrides(
    (
      panel-background-color: #fff,
    )
  );

  // TOOLTIP
  @include mat.tooltip-overrides(
    (
      container-color: white,
      supporting-text-color: #4c5f6b,
      supporting-text-size: 1rem,
      supporting-text-line-height: 1.5rem,
      supporting-text-weight: 400,
      container-shape: 8px,
      supporting-text-tracking: 0.5px,
    )
  );
  .mdc-tooltip__surface {
    max-width: unset !important;
    padding: 16px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5) !important;
  }

  // DIALOG
  @include mat.dialog-overrides(
    (
      container-shape: 4px,
    )
  );

  @include mat.tabs-overrides(
    (
      active-indicator-color: #38bce0,
      active-label-text-color: #01426a,
      inactive-label-text-color: #a1acb7,
      active-hover-label-text-color: #01426a,
      active-hover-indicator-color: #007ad9,
      active-focus-label-text-color: #01426a,
      active-focus-indicator-color: #38bce0,
    )
  );
}

// DENSITY CLASSES
.material-dense-positive-1 {
  @include mat.all-component-densities(1);
}

.material-dense-positive-2 {
  @include mat.all-component-densities(2);
}

.material-dense-positive-3 {
  @include mat.all-component-densities(3);
}

.material-dense-positive-4 {
  @include mat.all-component-densities(4);
}

.material-dense-1 {
  @include mat.all-component-densities(-1);
}

.material-dense-2 {
  @include mat.all-component-densities(-2);
}

.material-dense-3 {
  @include mat.all-component-densities(-3);
}

.material-dense-4 {
  @include mat.all-component-densities(-4);
}
